import React from "react";
import { getCaseLink, getProductData, getProductNameBy } from "../../../services/productService";
import InquiryToCustomerOverviewBox from "./InquiryToCustomerOverviewBox";
import Container from "../../Container/Container";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, fetchResource, queryKeys } from "../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../hooks/useProductParams";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import { InquiryToCustomerStatus } from "../../../types/Entities/InquiryToCustomer";
import InquiryToCustomerAppointmentBox from "./InquiryToCustomerAppointmentBox";
import InquiryToCustomerInformationAndDocuments from "./InquiryToCustomerInformationAndDocuments";
import { Navigate } from "react-router-dom";

export default function InquiryToCustomer() {
  const { productName, productId } = useProductParams();
  const productClassName = getProductNameBy("urlPartShort", productName);
  const productApiUri = getProductData(productClassName, "apiUri");

  const { data: product } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const inquiryFilters = {
    product: productClassName,
    productId: productId,
    "status[0]": InquiryToCustomerStatus.STATUS_OPEN,
    "status[1]": InquiryToCustomerStatus.STATUS_OVERDUE,
  };
  const inquiryApiUri = "/inquiry_to_customers";
  const { data: inquiries } = useQuery(queryKeys.collection(inquiryApiUri, inquiryFilters), () => fetchCollection(inquiryApiUri, inquiryFilters), {
    enabled: !!product,
  });

  if (!inquiries || !product) {
    return <LegalbirdLoader centered />;
  }

  const currentInquiryToCustomer = inquiries[0];

  if (!currentInquiryToCustomer) {
    return <Navigate to={getCaseLink(product)} />;
  }

  return (
    <Container>
      {[InquiryToCustomerStatus.STATUS_OPEN, InquiryToCustomerStatus.STATUS_OVERDUE].includes(currentInquiryToCustomer.status) && (
        <InquiryToCustomerOverviewBox inquiryToCustomer={currentInquiryToCustomer} />
      )}
      {currentInquiryToCustomer.inquiryType === "appointment" && (
        <InquiryToCustomerAppointmentBox inquiryToCustomer={currentInquiryToCustomer} product={product} />
      )}
      {currentInquiryToCustomer.inquiryType !== "appointment" && (
        <InquiryToCustomerInformationAndDocuments inquiryToCustomer={currentInquiryToCustomer} product={product} />
      )}
    </Container>
  );
}
