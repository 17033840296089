import React, { useState } from "react";
import InquiryToCustomer, { InquiryToCustomerStatus, RequestedDocument } from "../../../types/Entities/InquiryToCustomer";
import { Checkbox, Divider, Grid, Stack, Typography } from "@mui/material";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import { fileCheck, formValue, saveMediaObject } from "../../../services/formServiceFunctions";
import FormControlLabel from "@mui/material/FormControlLabel";
import FileUploadForm from "../../Form/Common/FileUploadSection/FileUploadForm";
import * as messagesActions from "../../../store/messages/actions";
import _head from "lodash/head";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createMediaObject } from "../../../services/reactQuery/reactQueryMediaObjectService";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import { queryKeys, updateResource } from "../../../services/reactQuery/reactQueryService";
import { MediaObject } from "../../../types/Entities/MediaObject/MediaObject";
import { useForm } from "../../../provider/Form/FormProvider";

type InquiryToCustomerDocumentsBoxProps = {
  inquiryToCustomer: InquiryToCustomer;
  product: AbstractCase;
};

export default function InquiryToCustomerDocumentsBox({ inquiryToCustomer, product }: InquiryToCustomerDocumentsBoxProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [filePath, setFilePath] = useState("");
  const createMediaObjectMutation = useMutation(createMediaObject);
  const updateMutation = useMutation(updateResource);
  const queryClient = useQueryClient();
  const { handleChange, values } = useForm();

  if (!inquiryToCustomer.requestedDocuments) {
    return null;
  }

  const updateInquiryToCustomerMediaObjects = async (requestedDocuments: Array<RequestedDocument>) => {
    const inquiryFilters = {
      product: product.name,
      productId: product.id,
      "status[0]": InquiryToCustomerStatus.STATUS_OPEN,
      "status[1]": InquiryToCustomerStatus.STATUS_OVERDUE,
    };

    const inquiryApiUri = "/inquiry_to_customers";

    await updateMutation.mutateAsync({
      uri: inquiryApiUri,
      id: inquiryToCustomer.id,
      data: {
        requestedDocuments: requestedDocuments,
      },
    });

    await queryClient.invalidateQueries(queryKeys.collection(inquiryApiUri, inquiryFilters));
  };

  const deleteMediaObject = async (mediaObjectToDelete: MediaObject, index: number) => {
    if (!inquiryToCustomer.requestedDocuments) {
      return;
    }
    setIsLoading(true);
    let requestedDocumentsClone = structuredClone(inquiryToCustomer.requestedDocuments);
    requestedDocumentsClone[index].temporaryFilesToCombine = requestedDocumentsClone[index].temporaryFilesToCombine.filter(
      (mediaObject) => mediaObject.id !== mediaObjectToDelete.id
    );
    await updateInquiryToCustomerMediaObjects(requestedDocumentsClone);
    setIsLoading(false);
  };

  const handleMediaobjectUpload = async (target: HTMLInputElement, index: number) => {
    if (!inquiryToCustomer.requestedDocuments) {
      return;
    }
    setIsLoading(true);
    const fileCheckRes = fileCheck(target);
    if (fileCheckRes.hasError) {
      messagesActions.addMessage({ type: "error", text: fileCheckRes.errorMsg });
      return;
    }
    const file = _head(target.files);
    //TODO improve. hacked because of deadline
    setFilePath(file ? index + file.name : index + "");

    const mediaObject = await saveMediaObject(createMediaObjectMutation, {
      file: file,
      fieldname: "inquiryTemporaryFile",
      description: "TODO",
      product: product.name,
      productId: product.id,
    });

    let requestedDocumentsClone = structuredClone(inquiryToCustomer.requestedDocuments);

    requestedDocumentsClone[index].temporaryFilesToCombine = [...requestedDocumentsClone[index].temporaryFilesToCombine, mediaObject];
    await updateInquiryToCustomerMediaObjects(requestedDocumentsClone);
    setFilePath("");
    setIsLoading(false);
  };

  return (
    <Stack spacing={2}>
      <Typography variant={"h4"} sx={{ textAlign: "center" }}>
        Benötigte Dokumente
      </Typography>
      {!!inquiryToCustomer.inquiryTextDocuments && <Typography>Ergänzende Hinweise zu den Dokumenten: {inquiryToCustomer.inquiryTextDocuments}</Typography>}
      {inquiryToCustomer.requestedDocuments.map((document, index) => {
        return (
          <>
            <Grid container key={index}>
              <Grid item xs={12} md={6}>
                <Typography variant={"h5"}>{document.documentName}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formValue(values, "requestedDocumentMissing" + index)}
                      name={"requestedDocumentMissing" + index}
                      onChange={handleChange}
                    />
                  }
                  label={"Dieses Dokument liegt mir leider nicht vor"}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ paddingTop: "2rem" }}>
                <FileUploadForm
                  mediaObjectCollection={document.temporaryFilesToCombine}
                  deleteMediaObject={(mediaObjectToDelete: MediaObject) => deleteMediaObject(mediaObjectToDelete, index)}
                  handleFileChange={({ target }: any) => handleMediaobjectUpload(target, index)}
                  fieldname={"inquiryTemporaryFile" + index}
                  allowMore={document.temporaryFilesToCombine.length < 1 || document.temporaryFilesToCombine[0].mimeType !== "application/pdf"}
                  filePath={filePath.substring(0, 1) === index + "" ? filePath.substring(1) : ""}
                  isBlocked={isLoading}
                  label={undefined}
                  helperText={undefined}
                  noSection={undefined}
                  headline={undefined}
                />
              </Grid>
            </Grid>
            {index + 1 !== inquiryToCustomer.requestedDocuments?.length && <Divider />}
          </>
        );
      })}
      <ValidatorTextField
        label="Ihre Anmerkungen und Fragen (optional)"
        name="inquiryTextDocumentsCustomer"
        placeholder={"Sie haben Anmerkungen oder Fragen zu den angefragten Dokumenten, dann teilen Sie uns diese gerne kurz hier mit."}
        multiline
        rows={5}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Stack>
  );
}
