import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import mainTeaserStyle from "./mainTeaserStyle";
import { Grid } from "@mui/material";
import manWithPhone from "../../assets/img/teaser/homepage/Client_with_Smartphone.svg";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { useCustomer } from "../../provider/Customer/CustomerProvider";

const useStyles = makeStyles(mainTeaserStyle);

export default function MainTeaser({ handleOpenLoginLayer }) {
  const classes = useStyles();
  const { isLoggedIn } = useCustomer();

  return (
    <div className={classes.mainTeaserContainer}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant={"h1"} className={classes.headline}>
            Recht leicht gemacht
          </Typography>
          <Typography color={"textSecondary"} className={classes.subText} variant={"subtitle1"}>
            Wir helfen Ihnen einfach, schnell und professionell. Egal wann und wo!
          </Typography>
          {!isLoggedIn && (
            <Button className={classes.button} variant={"contained"} color={"primary"} onClick={handleOpenLoginLayer}>
              Jetzt starten
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={manWithPhone} alt={"man with phone"} />
        </Grid>
      </Grid>
    </div>
  );
}

MainTeaser.propTypes = {
  handleOpenLoginLayer: PropTypes.func.isRequired,
};
