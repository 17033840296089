import Grid from "@mui/material/Grid";
import ValidatorSelectField from "../../FormFields/ValidatorSelectField";
import MenuItem from "@mui/material/MenuItem";
import EmailCheck from "../../EmailCheck/EmailCheck";
import ButtonLoading from "../../Button/ButtonLoading";
import ButtonTextTermsOfServicePrivacy from "../Common/ButtonTextTermsOfServicePrivacy/ButtonTextTermsOfServicePrivacy";
import React from "react";
import { useForm } from "../../../provider/Form/FormProvider";
import useStyles from "./dismissalCheckinStyle";
import { emailDefault, requiredValidator } from "../../../services/validationRules";

export default function DismissalCheckinForm({ isLoading, emailErrorText, setEmailErrorText }) {
  const { handleSubmit } = useForm();
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} className={classes.gridSpaceAccusation}>
          <ValidatorSelectField
            label={"Anzahl Mitarbeiter im Unternehmen"}
            name={"numberOfEmployees"}
            validators={[
              requiredValidator("Bitte geben Sie an, wie viele Mitarbeiter in Ihrem Unternehmen beschäftigt sind."),
            ]}
          >
            <MenuItem value={"10"}>0 - 10</MenuItem>
            <MenuItem value={"50"}>11 - 50</MenuItem>
            <MenuItem value={"500"}>51 - 500</MenuItem>
            <MenuItem value={"1000"}>Mehr als 500</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridSpaceEmail}>
          <EmailCheck
            name="email"
            label="Ihre E-Mail-Adresse"
            autoComplete="email"
            emailErrorText={emailErrorText}
            setEmailErrorText={setEmailErrorText}
            validators={[requiredValidator("Bitte geben Sie Ihre E-Mail-Adresse an"), emailDefault]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.buttonContainer}>
          <ButtonLoading
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
            fullWidth
            isLoading={isLoading}
          >
            Kostenlos prüfen lassen
          </ButtonLoading>
          <ButtonTextTermsOfServicePrivacy />
        </Grid>
      </Grid>
    </form>
  );
}
