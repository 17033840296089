import ReactGA from "react-ga4";
import ApiClient from "./apiClient";
import { v4 as uuidv4 } from "uuid";
import _merge from "lodash/merge";
import moment from "moment";

export const setConsent = (data) => {
  const fullData = _merge({}, data, {
    hash: uuidv4(),
    timestamp: new Date().getTime(),
  });
  localStorage.setItem("consent", JSON.stringify(fullData));
  ApiClient.post("/cookie_consents", {
    body: JSON.stringify({
      hash: fullData.hash,
      consentData: fullData,
    }),
  });
};

export const hasConsent = (key) => {
  const consent = getConsent();
  switch (consent?.consentType) {
    case "deny":
      return false;
    case "allow":
      return true;
    case "configured":
      return consent.config[key];
    default:
      return false;
  }
};

export const getConsent = () => {
  const consentJSON = localStorage.getItem("consent");
  if (!consentJSON) {
    return null;
  }
  return JSON.parse(consentJSON);
};

export const checkConsentEntry = () => {
  const consentFromLocalStorage = getConsent();

  if (!consentFromLocalStorage) {
    return false;
  }
  const consentDateTime = moment.unix(consentFromLocalStorage.timestamp / 1000);
  if (consentDateTime.isValid() && consentDateTime.isBefore(moment().subtract(6, "months"))) {
    removeConsentEntry();
    return false;
  }
  return true;
};

export const denyAll = () => {
  setConsent({
    consentType: "deny",
  });
};

export const allowAll = () => {
  setConsent({
    consentType: "allow",
  });
};

export const gaSet = (data) => {
  if (!hasConsent("analytics")) {
    return;
  }
  ReactGA.set(data);
};

const removeConsentEntry = () => {
  localStorage.removeItem("consent");
};
