/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import moment from "moment";
import { electronicFormatIBAN, isValidIBAN } from "ibantools";
import _isEmpty from "lodash/isEmpty";
import _isString from "lodash/isString";
import _includes from "lodash/includes";
import ApiClient from "./apiClient";
import { ValidatorType } from "../types/ValidatorType";

export const isNotExpiredSettlementDate = (value: moment.MomentInput) => {
  return moment(value, ["DD.MM.YYYY", "DD.M.YYYY", "D.MM.YYYY", "D.M.YYYY"], true).isSameOrAfter(moment().subtract(3, "weeks"), "day");
};

export const isTodayOrPastDate = (value: moment.MomentInput) => {
  return moment(value, ["DD.MM.YYYY", "DD.M.YYYY", "D.MM.YYYY", "D.M.YYYY"], true).isSameOrBefore(new Date(), "day");
};

export const isPastDate = (value: moment.MomentInput) => {
  return _isEmpty(value) || moment(value, ["DD.MM.YYYY", "DD.M.YYYY", "D.MM.YYYY", "D.M.YYYY"], true).isBefore(new Date(), "day");
};

export const isValidDate = (value: any) => {
  if (value === undefined || value === null) {
    return true;
  }
  if (_isString(value)) {
    return _isEmpty(value) || moment(value, ["YYYY-MM-DD", "DD.MM.YYYY", "DD.M.YYYY", "D.MM.YYYY", "D.M.YYYY"], true).isValid();
  }
  return value.isValid();
};

export const matchRegexp = (value: string, regexp: string | RegExp) => {
  const validationRegexp = regexp instanceof RegExp ? regexp : new RegExp(regexp);
  return _isEmpty(value) || validationRegexp.test(value);
};

export const isMoney = (value: string) => {
  return _isEmpty(value) || matchRegexp(value, /^-?\d+(\.\d+)*(,\d{1,2})?$/);
};

export const isIban = (value: string) => {
  const iban = "DE" + value;
  return _isEmpty(value) || isValidIBAN(<string>electronicFormatIBAN(iban));
};

export const isEmail = (value: string) => {
  return matchRegexp(
    value,
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/i
  );
};

export const isEmpty = (value: any) => {
  return _isEmpty(value);
};

export const isRequired = (value: any) => {
  return !_isEmpty(value);
};

export const isOneOf = (value: any, ...options: any[]) => {
  return _includes(options, value);
};

export const isNumber = (value: string) => {
  return matchRegexp(value, /^-?[0-9]\d*(\d+)?$/i);
};

export const isPhoneNumber = (value: string) => {
  return _isEmpty(value) || value.match(/^[0-9 \-\/+]*$/);
};

export const isFloat = (value: string) => {
  return matchRegexp(value, /^(?:-?[1-9]\d*|-?0)?(?:\.\d{1,2})?$/i);
};

export const maxNumber = (value: string, max: string) => {
  return _isEmpty(value) || parseInt(value, 10) <= parseInt(max, 10);
};

export const minNumber = (value: string, min: string) => {
  return _isEmpty(value) || parseInt(value, 10) >= parseInt(min, 10);
};

export const minStringLength = (value: string | any[], length: number) => {
  return _isEmpty(value) || (_isString(value) && value.length >= length);
};

export const maxStringLength = (value: string | any[], length: number) => {
  return _isEmpty(value) || (_isString(value) && value.length <= length);
};

export const isNotDuplicateMail = async (value: string) => {
  //to prevent backend error when mail has the wrong format
  //if this validator comes before the isEmail validator, this results in the "duplicate email" error message even though it just has the wrong format
  if (!isEmail(value)) {
    return false;
  }

  const emailCheckResponse = await ApiClient.post("/application/check_email", {
    body: JSON.stringify({ email: value.toLowerCase() }),
  });
  return !emailCheckResponse?.emailExists;
};

export const postalCodeDefault: ValidatorType = {
  validator: matchRegexp,
  params: [/^[0-9]{3,6}$/],
  message: "Die Postleitzahl hat ein ungültiges Format",
};

export const streetAddressDefault: ValidatorType = {
  validator: matchRegexp,
  params: [/^([a-zA-ZäÄöÖüÜß]{2}.*|[a-uA-U][0-9]{1,2})\s.*[0-9]+.*$/],
  message: "Bitte geben Sie Ihre Straße und Hausnummer an",
};

export const dateFieldDefault: ValidatorType = {
  validator: isValidDate,
  message: "Bitte prüfen Sie Ihre Angabe. Das Datum muss im Format TT.MM.JJJJ angegeben werden (z.B. 01.03.2010).",
};

export const pastDateDefault: ValidatorType = {
  validator: isPastDate,
  message: "Das Datum muss in der Vergangenheit liegen.",
};

export const pastOrSameDateDefault: ValidatorType = {
  validator: isTodayOrPastDate,
  message: "Das Datum darf nicht in der Zukunft liegen.",
};

export const numberFieldDefault: ValidatorType = {
  validator: isNumber,
  message: "Die Eingabe muss eine Ganzzahl sein",
};

export const floatFieldValidator: ValidatorType = {
  validator: isFloat,
  message: "Die Eingabe muss eine Zahl sein und darf maximal zwei Nachkommastellen haben.",
};

export const moneyFieldDefault: ValidatorType = {
  validator: isMoney,
  message: "Bitte geben Sie den Betrag in € an",
};

export const requiredFieldDefault: ValidatorType = {
  validator: isRequired,
  message: "Dies ist ein Pflichtfeld",
};

export const requiredValidator = (message = "Dies ist ein Pflichtfeld"): ValidatorType => {
  return {
    validator: isRequired,
    message: message,
  };
};

export const oneOfTheseOptionsValidator = (options: string[], message: string = "Wählen Sie bitte eine der Optionen aus"): ValidatorType => {
  return {
    validator: isOneOf,
    params: options,
    message: message,
  };
};

export const emailDefault: ValidatorType = {
  validator: isEmail,
  message: "Bitte geben Sie eine gültige E-Mail-Adresse an",
};

export const textFieldDefault: ValidatorType = {
  validator: minStringLength,
  params: [2],
  message: "Der Text ist zu kurz",
};

export const isPhoneNumberDefault: ValidatorType = {
  validator: isPhoneNumber,
  message: "Die Telefonnummer darf keine Sonderzeichen enthalten oder mehrere führende Nullen haben.",
};
