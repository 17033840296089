/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {schufaCheckinStyles} from "./schufaCheckinStyles";
import poaExample from "../../../../../../assets/img/products/Vollmacht_Schufa-Anfrage.jpg";
import SignaturePad from "../../../../../../components/SignaturePad/SignaturePad";
import Paper from "@mui/material/Paper";
import {Box, Link, Stack} from "@mui/material";
import {Replay as ReplayIcon, VisibilityOutlined} from "@mui/icons-material";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";

type CheckInPowerOfAttorneyProps = {
  signaturePadRef: any;
};

export default function CheckInPowerOfAttorney({ signaturePadRef }: CheckInPowerOfAttorneyProps) {
  const clearSignature = () => {
    signaturePadRef.current.signaturePadInstance.clear();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"subtitle2"} sx={schufaCheckinStyles.inFormHeading}>
          Damit wir deinen Fall bearbeiten und eine SCHUFA-Anfrage stellen können, benötigen wir deine Vollmacht. Wenn die Prüfung ergibt, dass ein
          Schadensersatz erwirkt werden kann, kümmern wir uns für dich um die Kostendeckung durch die Rechtsschutzversicherung.{" "}
          <strong>Alles ist für dich somit jederzeit kostenlos!</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack justifyContent={"center"} sx={{height: "100%"}}>
          <Box sx={schufaCheckinStyles.powerOfAttorneyPreview}>
            <Stack justifyContent={"center"} sx={{ height: "100%" }}>
              <Button fullWidth color={"primary"} sx={schufaCheckinStyles.previewButton} component={Link} target={"_blank"} href={poaExample}>
                <Grid container alignItems={"center"} justifyContent={"space-evenly"}>
                  <Grid item>
                    <Typography>Vollmacht lesen</Typography>
                  </Grid>
                  <Grid item>
                    <Stack justifyContent={"center"}>
                      <VisibilityOutlined sx={{ width: 20, height: 20 }} />
                    </Stack>
                  </Grid>
                </Grid>
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12} md={8}>
        <Stack justifyContent={"center"} sx={{ height: "100%" }} spacing={2}>
          <Typography variant={"subtitle2"} sx={schufaCheckinStyles.heading}>
            Deine Vollmacht unterzeichnen
          </Typography>
          <Hidden smUp>
            <Typography>
              <strong>TIPP:</strong> Drehen Sie Ihr Handy, damit Sie mehr Platz zum Unterzeichnen haben.
            </Typography>
          </Hidden>
          <Paper>
            <SignaturePad height={200} ref={signaturePadRef} />
          </Paper>
          <Button color={"primary"} onClick={clearSignature}>
            <ReplayIcon />
            <Typography sx={{ marginLeft: 1 }}>Erneut unterzeichnen</Typography>
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}
