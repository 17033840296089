import TextField from "@mui/material/TextField";
import ButtonLoading from "../Button/ButtonLoading";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import feedbackStyles from "./feedbackStyles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(feedbackStyles);
export default function FeedbackComment({ feedbackEntity, stars, saveFeedback }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState(null);

  if (!!feedbackEntity.feedbackDateTime) {
    if (feedbackEntity.isFinalReview && feedbackEntity.stars === 5) {
      return (
        <>
          <Typography sx={{ color: "primary.main" }} variant={"h5"}>
            Helfen Sie jetzt auch anderen Kunden, sich zu orientieren!
          </Typography>
          <p>Über das Bewertungsportal Proven Expert können Sie die Unterstützung durch Legalbird öffentlich sichtbar darstellen und bewerten.</p>
          <p>Dies dauert nur eine Minute und hilft anderen Rechtsuchenden bei der Auswahl der richtigen Kanzlei.</p>
          <div className={classes.ctaButtonContainer}>
            <Button fullWidth={true} color="primary" variant="contained" component="a" href="https://www.provenexpert.com/rwbj76/" target="_blank">
              Auf Proven Expert bewerten
            </Button>
          </div>
          <p>Wir bedanken uns im Voraus und unterstützen Sie in Zukunft jederzeit gerne wieder!</p>
        </>
      );
    }
    return (
      <>
        <p>Ihre Bewertung {!!feedbackEntity.comment ? "und Ihr Kommentar wurden" : "wurde"} gespeichert</p>
        <Button fullWidth color="primary" component={Link} to={"/servicewelt"}>
          Zu meiner Servicewelt
        </Button>
      </>
    );
  }

  const handleSubmit = () => {
    setIsLoading(true);
    saveFeedback({
      hash: feedbackEntity.hash,
      comment: comment,
      stars: stars,
    }).then(
      () => setIsLoading(false),
      () => setIsLoading(false)
    );
  };

  return (
    <>
      {feedbackEntity.source !== "footer" ? (
        <div className={classes.commentContainer}>
          <p>Bitte hinterlassen Sie uns noch einen kurzen Kommentar zu Ihrer Bewertung.</p>
          <TextField
            label="Ihr Kommentar"
            name="comment"
            value={comment}
            onChange={({ target }) => setComment(target.value)}
            multiline
            rows="4"
            maxRows="4"
            margin="normal"
            fullWidth
          />
        </div>
      ) : (
        <p>Mit Ihrer Bewertung helfen Sie uns dabei, uns verbessern zu können.</p>
      )}
      <div className={classes.ctaButtonContainer}>
        <ButtonLoading fullWidth={true} color="primary" variant="contained" onClick={handleSubmit} isLoading={isLoading} disabled={!stars}>
          Bewertung speichern
        </ButtonLoading>
      </div>
      <Button fullWidth color="primary" component={Link} to={"/servicewelt"}>
        Zu meiner Servicewelt
      </Button>
    </>
  );
}
