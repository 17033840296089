import _get from "lodash/get";
import _camelCase from "lodash/camelCase";
import _findKey from "lodash/findKey";
import formStepsAlimony from "./alimony/formSteps";
import FormServiceAlimony from "./alimony/FormService";
import formStepsDivorce from "./divorce/formSteps";
import FormServiceDivorce from "./divorce/FormService";
import formStepsDeath from "./death/formSteps";
import FormServiceDeath from "./death/FormService";
import formStepsContractLaw from "./contractLaw/formSteps";
import FormServiceContractLaw from "./contractLaw/FormService";
import formStepsProcesCostSupport from "./processCostSupport/formSteps";
import FormServiceProcessCostSupport from "./processCostSupport/FormService";
import formStepsFamily from "./familyLaw/formSteps";
import FormServiceFamily from "./familyLaw/FormService";
import formStepsSettlement from "./settlement/formSteps";
import FormServiceSettlement from "./settlement/FormService";
import formStepsShortTimeWork from "./shortTimeWork/formSteps";
import FormServiceShortTimeWork from "./shortTimeWork/FormService";
import formStepsTraffic from "./traffic/formSteps";
import FormServiceTraffic from "./traffic/FormService";
import { APPLICATION_STATUS_SUMMARY as APPLICATION_STATUS_SUMMARY_ALIMONY } from "./alimony/applicationStatus";
import { APPLICATION_STATUS_SUMMARY as APPLICATION_STATUS_SUMMARY_DIVORCE } from "./divorce/applicationStatus";
import { APPLICATION_STATUS_SUMMARY as APPLICATION_STATUS_SUMMARY_DEATH } from "./death/applicationStatus";
import { APPLICATION_STATUS_SUMMARY as APPLICATION_STATUS_SUMMARY_CONTRACT } from "./contractLaw/applicationStatus";
import { APPLICATION_STATUS_SUMMARY as APPLICATION_STATUS_SUMMARY_SETTLEMENT } from "./settlement/applicationStatus";
import { APPLICATION_STATUS_SUMMARY as APPLICATION_STATUS_SUMMARY_SHORTTIMEWORK } from "./shortTimeWork/applicationStatus";
import { APPLICATION_STATUS_SUMMARY as APPLICATION_STATUS_SUMMARY_TRAFFIC } from "./traffic/applicationStatus";
import { APPLICATION_STATUS_SUMMARY as APPLICATION_STATUS_SUMMARY_PCS } from "./processCostSupport/applicationStatus";
import contractIcon from "../assets/img/generic/icon_documents.png";
import familyIcon from "../assets/icons/familyLaw/familie_colored.png";
import trafficIcon from "../assets/icons/traffic/icon_verkehrsrecht.png";
import labourIcon from "../assets/img/products/icon_arbeitsrecht.png";
import iconDivorce from "../assets/icons/familyLaw/icon_scheidung.png";
import iconAlimony from "../assets/icons/familyLaw/icon_dokument_unterhalt_150px_centered.png";
import iconDeath from "../assets/icons/familyLaw/icon_dokument_tod_erbe_150px_centered.png";
import iconFamily from "../assets/icons/familyLaw/icon_dokument_erstberatung_150px_centered.png";
import iconContract from "../assets/icons/serviceOverview/icon_contract_law_my_cases.png";
import _includes from "lodash/includes";
import iconShortTimeWork from "../assets/icons/shortTimeWork/icon_kurzarbeit.png";
import iconSettlement from "../assets/icons/settlement/icon_abfindung.png";

export const productCategoryData = {
  contractLaw: {
    label: "Vertragsrecht",
    icon: contractIcon,
  },
  familyLaw: {
    label: "Familienrecht",
    icon: familyIcon,
  },
  trafficLaw: {
    label: "Verkehrsrecht",
    icon: trafficIcon,
  },
  labourLaw: {
    label: "Arbeitsrecht",
    icon: labourIcon,
  },
};

const productData = {
  alimony: {
    urlPart: "familienrecht/unterhalt",
    urlPartBase: "familienrecht/unterhalt",
    urlPartShort: "unterhalt",
    urlPartProductCategory: "familienrecht",
    label: "Erstberatung Unterhalt",
    apiUri: "/alimonies",
    formSteps: formStepsAlimony,
    formService: FormServiceAlimony,
    summaryStatus: APPLICATION_STATUS_SUMMARY_ALIMONY,
    productCategory: productCategoryData.familyLaw,
    productIcon: iconAlimony,
  },
  divorce: {
    urlPart: "familienrecht/scheidung",
    urlPartBase: "familienrecht",
    urlPartShort: "scheidung",
    urlPartProductCategory: "familienrecht",
    label: "Scheidung",
    apiUri: "/divorces",
    formSteps: formStepsDivorce,
    formService: FormServiceDivorce,
    summaryStatus: APPLICATION_STATUS_SUMMARY_DIVORCE,
    productCategory: productCategoryData.familyLaw,
    productIcon: iconDivorce,
  },
  death: {
    urlPart: "familienrecht/todesfall",
    urlPartBase: "familienrecht/todesfall",
    urlPartShort: "todesfall",
    urlPartProductCategory: "familienrecht",
    label: "Erstberatung Erbrecht/Todesfall",
    apiUri: "/deaths",
    formSteps: formStepsDeath,
    formService: FormServiceDeath,
    summaryStatus: APPLICATION_STATUS_SUMMARY_DEATH,
    productCategory: productCategoryData.familyLaw,
    productIcon: iconDeath,
  },
  eventContract: {
    urlPart: "vertragsrecht/veranstaltung",
    urlPartBase: "vertragsrecht",
    urlPartShort: "veranstaltung",
    urlPartProductCategory: "vertragsrecht",
    label: "Veranstaltung",
    apiUri: "/event_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
  },
  familyLawCase: {
    urlPart: "familienrecht/erstberatung",
    urlPartBase: "familienrecht",
    urlPartShort: "erstberatung",
    urlPartProductCategory: "familienrecht",
    label: "Erstberatung Familienrecht",
    apiUri: "/family_law_cases",
    formSteps: formStepsFamily,
    formService: FormServiceFamily,
    summaryStatus: 1000,
    productCategory: productCategoryData.familyLaw,
    productIcon: iconFamily,
  },
  generic: {
    urlPart: "",
  },
  legalbird: {
    urlPart: "",
  },
  membershipContract: {
    urlPart: "vertragsrecht/mitgliedschaft",
    urlPartBase: "vertragsrecht",
    urlPartShort: "mitgliedschaft",
    urlPartProductCategory: "vertragsrecht",
    label: "Mitgliedschaft",
    apiUri: "/membership_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
  },
  otherContract: {
    urlPart: "vertragsrecht/sonstige",
    urlPartBase: "vertragsrecht",
    urlPartShort: "sonstige",
    urlPartProductCategory: "vertragsrecht",
    label: "Sonstiger Vertrag",
    apiUri: "/other_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
  },
  processCostSupport: {
    apiUri: "/process_cost_supports",
    urlPart: "scheidung",
    urlPartProductCategory: "familienrecht",
    urlPartShort: "scheidung",
    formSteps: formStepsProcesCostSupport,
    formService: FormServiceProcessCostSupport,
    summaryStatus: APPLICATION_STATUS_SUMMARY_PCS
  },
  providerContract: {
    urlPart: "vertragsrecht/versorger",
    urlPartBase: "vertragsrecht",
    urlPartShort: "versorger",
    urlPartProductCategory: "vertragsrecht",
    label: "Versorgervertrag",
    apiUri: "/provider_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
  },
  provision: {
    urlPart: "vorsorge",
  },
  purchaseContract: {
    urlPart: "vertragsrecht/kauf",
    urlPartBase: "vertragsrecht",
    urlPartShort: "kauf",
    urlPartProductCategory: "vertragsrecht",
    label: "Kaufvertrag",
    apiUri: "/purchase_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
  },
  serviceContract: {
    urlPart: "vertragsrecht/dienstleistung",
    urlPartBase: "vertragsrecht",
    urlPartShort: "dienstleistung",
    urlPartProductCategory: "vertragsrecht",
    label: "Dienstleistungsvertrag",
    apiUri: "/service_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
  },
  settlement: {
    urlPart: "arbeitsrecht/kuendigung",
    urlPartBase: "kuendigung",
    urlPartShort: "kuendigung",
    urlPartProductCategory: "arbeitsrecht",
    label: "Kündigungsschutzklage",
    apiUri: "/settlements",
    formSteps: formStepsSettlement,
    formService: FormServiceSettlement,
    summaryStatus: APPLICATION_STATUS_SUMMARY_SETTLEMENT,
    productCategory: productCategoryData.labourLaw,
    productIcon: iconSettlement,
  },
  shortTimeWork: {
    urlPart: "arbeitsrecht/kurzarbeit",
    urlPartBase: "kurzarbeit",
    urlPartShort: "kurzarbeit",
    urlPartProductCategory: "arbeitsrecht",
    label: "Erstberatung Kurzarbeit",
    apiUri: "/short_time_works",
    formSteps: formStepsShortTimeWork,
    formService: FormServiceShortTimeWork,
    summaryStatus: APPLICATION_STATUS_SUMMARY_SHORTTIMEWORK,
    productCategory: productCategoryData.labourLaw,
    productIcon: iconShortTimeWork,
  },
  subscriptionContract: {
    urlPart: "vertragsrecht/abonnement",
    urlPartBase: "vertragsrecht",
    urlPartShort: "abonnement",
    urlPartProductCategory: "vertragsrecht",
    label: "Abonnement",
    apiUri: "/subscription_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
  },
  traffic: {
    urlPart: "verkehrsrecht/ordnungswidrigkeit",
    urlPartBase: "ordnungswidrigkeit",
    urlPartShort: "ordnungswidrigkeit",
    urlPartProductCategory: "verkehrsrecht",
    label: "Ordnungswidrigkeit",
    apiUri: "/traffic",
    formSteps: formStepsTraffic,
    formService: FormServiceTraffic,
    summaryStatus: APPLICATION_STATUS_SUMMARY_TRAFFIC,
    productCategory: productCategoryData.trafficLaw,
    productIcon: trafficIcon,
  },
  travelContract: {
    urlPart: "vertragsrecht/reise",
    urlPartBase: "vertragsrecht",
    urlPartShort: "reise",
    urlPartProductCategory: "vertragsrecht",
    label: "Reisevertrag",
    apiUri: "/travel_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
  },
  vehicleContract: {
    urlPart: "vertragsrecht/fahrzeugkauf",
    urlPartBase: "vertragsrecht",
    urlPartShort: "fahrzeugkauf",
    urlPartProductCategory: "vertragsrecht",
    label: "Fahrzeugkaufvertrag",
    apiUri: "/vehicle_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
  },
};

export const getProductData = (productClassName, path, defaultValue = "") => {
  productClassName = _camelCase(productClassName);
  return _get(productData, `${productClassName}.${path}`, defaultValue);
};

export const getProductNameBy = (path, value) => {
  return _findKey(productData, [path, value]);
};

export const getCaseLink = (product) => {
  if (product.name === "processCostSupport") {
    return getCaseLink(product.divorce) + "/vkh-assistent";
  }
  return (
    "/servicewelt/" +
    getProductData(product.name, "urlPartProductCategory") +
    "/" +
    getProductData(product.name, "urlPartShort") +
    "/" +
    product.id
  );
};

export const isInsurancePartnered = (product) => {
  return _includes(["advocard", "arag", "roland"], product.acquisitionPartner);
}
