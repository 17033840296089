import React from "react";
import { useForm } from "../../../../provider/Form/FormProvider";
import DateFieldConfigured from "../../ConfiguredFormElements/DateFieldConfigured/DateFieldConfigured";
import { FormHeaderWrapper } from "../../Common/FormHeaderWrapper/FormHeaderWrapper";
import SingleSelectFieldConfigured from "../../ConfiguredFormElements/SingleSelectFieldConfigured/SingleSelectFieldConfigured";
import TextFieldConfigured from "../../ConfiguredFormElements/TextFieldConfigured/TextFieldConfigured";
import RadioGroupConfigured from "../../ConfiguredFormElements/RadioGroupConfigured/RadioGroupConfigured";
import { ContractDetailsProps } from "../../../../types/ContractDetailsProps";
import { getIsSpecialWbsSchufaCase } from "../../../../services/formServiceFunctions";
import Grid from "@mui/material/Grid";
import FormLegend from "../../Common/FormLegend/FormLegend";
import MobileProvidersAutocomplete from "../../MobileProvidersAutoComplete/MobileProvidersAutocomplete";

export default function ProviderContractDetails({ product, submitProps }: ContractDetailsProps) {
  const { values, handleSubmit }: any = useForm();
  const isSpecialWbsSchufaCase = getIsSpecialWbsSchufaCase(product);
  return (
    <FormHeaderWrapper submitProps={submitProps} handleSubmit={handleSubmit}>
      {!isSpecialWbsSchufaCase && <SingleSelectFieldConfigured caseData={values} fieldPath={"providerType"} />}
      {!isSpecialWbsSchufaCase && <RadioGroupConfigured caseData={values} fieldPath={"reasonForDispute"} required />}

      {values.reasonForDispute === "cancellationByProvider" && (
        <DateFieldConfigured caseData={product} fieldPath={"cancellationReceivedDate"} dateValidation={"pastOrToday"} />
      )}

      {values.reasonForDispute === "priceIncrease" && (
        <DateFieldConfigured caseData={product} fieldPath={"priceIncreaseAnnouncementDate"} dateValidation={"pastOrToday"} />
      )}

      {values.reasonForDispute === "cancellationRefused" && (
        <>
          <DateFieldConfigured caseData={product} fieldPath={"cancellationDate"} dateValidation={"pastOrToday"} required />
          <DateFieldConfigured caseData={product} fieldPath={"endOfContractDate"} />
          <RadioGroupConfigured caseData={values} fieldPath={"opponentReactionToCancellation"} required />
          {values.opponentReactionToCancellation === "differentDate" && <DateFieldConfigured caseData={product} fieldPath={"endOfContractDateOpponent"} />}
        </>
      )}
      {values.reasonForDispute === "schufaPositiveData" && (
        <Grid item xs={12}>
          <FormLegend>Bei welchem Mobilfunkanbieter sind Sie?</FormLegend>
          <MobileProvidersAutocomplete name={"mobileProviderOrganizationId"} requiredMessage={"Bitte gib deinen aktuellen Mobilfunkanbieter an"} />
        </Grid>
      )}
      <TextFieldConfigured caseData={product} fieldPath={"contractNumber"} required />
      {values.reasonForDispute === "schufaPositiveData" && (
        <>
          <RadioGroupConfigured caseData={values} fieldPath={"salesChannel"} required />
          {!product.clientWasMinorAtContractDate && (
            <>
              <Grid item xs={12}>
                <FormLegend>
                  {isSpecialWbsSchufaCase
                    ? "Für die SCHUFA-Anfrage benötigen wir zudem noch Ihr Geburtsdatum."
                    : "Folgende Daten von Ihnen werden benötigt, um eine Datenabfrage bei der SCHUFA einzureichen."}
                </FormLegend>
              </Grid>
              <DateFieldConfigured caseData={values} fieldPath={"customer.person.birthDate"} dateValidation={"past"} required />
            </>
          )}
          {/*<RadioGroupConfigured caseData={values} fieldPath={"clientMovedRecently"} required />
          {values.clientMovedRecently === "yes" && (
            <>
              <Address headline={"Bitte nennen Sie uns die Adresse des alten Wohnsitzes."} path={"client.previousResidenceAddress"} fieldsAreRequired />
            </>
          )}
          <RadioGroupConfigured caseData={values} fieldPath={"hasSecondaryResidence"} required />
          {values.hasSecondaryResidence === "yes" && (
            <>
              <Address headline={"Wo befindet sich Ihr zweiter Wohnsitz?"} path={"client.secondaryResidenceAddress"} fieldsAreRequired />
            </>
          )}*/}
        </>
      )}
    </FormHeaderWrapper>
  );
}
