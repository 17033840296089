import * as leadStatusDivorce from "./divorce/leadStatus";
import * as leadStatusSettlement from "./settlement/leadStatus";
import * as leadStatusTraffic from "./traffic/leadStatus";
import * as leadStatusDocument from "./document/leadStatus";
import _get from "lodash/get";
import _includes from "lodash/includes";
import iconLaw from "../assets/icons/serviceOverview/icon_gericht_150px.png";
import iconCalenderCheck from "../assets/icons/serviceOverview/icon_gerichtsgebaeude_150px.png";
import iconCourt from "../assets/icons/serviceOverview/icon_gerichtsgebaeude_150px.png";
import { getCaseLink, getProductData } from "./productService";
import { Link } from "react-router-dom";
import React from "react";
import { Button } from "@mui/material";
import iconAssistant from "../assets/icons/serviceOverview/icon_assistant_100px.png";
import iconAssistent from "../assets/icons/serviceOverview/icon_assistant_150px.png";
import { getStatusBoxConfigDivorce } from "./divorce/statusBoxSteps";
import { getStatusBoxConfigSettlement } from "./settlement/statusBoxSteps";
import { getStatusBoxConfigTraffic } from "./traffic/statusBoxSteps";
import { getStatusBoxConfigFamilyLawCase } from "./familyLaw/statusBoxSteps";
import { getStatusBoxConfigAlimony } from "./alimony/statusBoxSteps";
import { getStatusBoxConfigDeath } from "./death/statusBoxSteps";
import { getStatusBoxConfigContractLawCase } from "./contractLaw/statusBoxSteps";
import { getStatusBoxConfigShortTimeWork } from "./shortTimeWork/statusBoxSteps";
import iconDocument from "../assets/icons/serviceOverview/icon_antrag_erstellen_150px.png";
import iconDownload from "../assets/icons/serviceOverview/icon_dokumente_download_150px.png";
import iconTipps from "../assets/icons/serviceOverview/icon_tipps_100px.png";
import _orderBy from "lodash/orderBy";
import _remove from "lodash/remove";

export const getProcessConfig = (product) => {
  const formLink = (product) => {
    if (!product.id) {
      return "/servicewelt/" + getProductData(product.name, "urlPart") + "/antrag";
    }
    return getCaseLink(product) + "/antrag";
  };

  const processConfig = {
    divorce: {
      headline: "Ablauf",
      steps: [
        {
          headline: "1. Scheidungsantrag",
          content: (
            <>
              Sie füllen das {product.leadStatus < leadStatusDivorce.STATUS_DATA_COMPLETED ? <Link to={formLink(product)}>Formular</Link> : "Formular"} aus,
              erteilen die Vollmacht und wir erstellen den Scheidungsantrag für Sie.
            </>
          ),
          done: product.leadStatus > leadStatusDivorce.STATUS_DATA_COMPLETED,
          icon: iconDocument,
        },
        {
          headline: "2. Scheidung einreichen",
          content: "Wir reichen für Sie den Scheidungsantrag ein.",
          done: product.leadStatus > leadStatusDivorce.STATUS_PROCESS_PAID,
          icon: iconLaw,
        },
        {
          headline: "3. Scheidungstermin",
          content: "Das Gericht weist Ihnen einen Scheidungstermin zu und Sie werden geschieden.",
          done: product.leadStatus > leadStatusDivorce.STATUS_DIVORCE_DONE,
          icon: iconCalenderCheck,
        },
      ],
    },
    settlement: {
      headline: "Ablauf",
      steps: [
        {
          headline: "1. Kündigung prüfen lassen",
          content: (
            <>
              Sie füllen das <Link to={formLink(product)}>Formular</Link> aus, erteilen uns die Vollmacht und wir prüfen kostenlos Ihre Kündigung.
            </>
          ),
          done: product.leadStatus >= leadStatusSettlement.STATUS_POWER_OF_ATTORNEY_GRANTED,
          icon: iconDocument,
        },
        {
          headline: "2. Klage einreichen",
          content: "Rechtswidrige Kündigung? Dann reichen wir eine Kündigungsschutzklage für Sie ein.",
          done: product.leadStatus >= leadStatusSettlement.STATUS_SUBMITTED,
          icon: iconAssistant,
        },
        {
          headline: "3. Einigung oder Urteil",
          content: "Durch eine Einigung mit dem Arbeitgeber oder per Urteil, kommt es zur Entscheidung bezüglich Ihrer Klage.",
          done: product.leadStatus >= leadStatusSettlement.STATUS_DECISION_MADE,
          icon: iconCalenderCheck,
        },
      ],
    },
    traffic: {
      headline: "Zum Ablauf",
      steps: [
        {
          headline: "1. Bescheid prüfen lassen",
          content: (
            <>
              <Link to={formLink(product)}>Sie schildern hier kurz</Link>, was Ihnen vorgeworfen wird und Ihr Bescheid wird kostenlos geprüft
            </>
          ),
          done: product.leadStatus >= leadStatusTraffic.STATUS_DATA_COMPLETED,
          icon: iconDocument,
        },
        {
          headline: "2. Vorgehen besprechen",
          content: "Wir besprechen das weitere Vorgehen & Sie erteilen die Vollmacht",
          done: product.leadStatus >= leadStatusTraffic.STATUS_POWER_OF_ATTORNEY_GRANTED,
          icon: iconAssistent,
        },
        {
          headline: "3. Einigung oder Urteil",
          content: "Wir fordern Akteneinsicht an und leiten alle Schritte ein, um den Vorwurf abzuwenden",
          done: product.leadStatus >= leadStatusTraffic.STATUS_DECISION_MADE,
          icon: iconCalenderCheck,
        },
      ],
    },
    familyLawCase: {
      headline: "Erstberatung vereinbaren",
      steps: [
        {
          headline: "1. Kurz Frage schildern",
          content: "Sie beschreiben kurz Ihr Anliegen, damit wir Ihnen bestmöglich helfen können.",
          done: false,
          icon: iconDocument,
        },
        {
          headline: "2. Erstgespräch vereinbaren",
          content: "Sie vereinbaren einen Termin für ein Erstgespräch.",
          done: false,
          icon: iconAssistent,
        },
        {
          headline: "3. Weiteres Vorgehen",
          content: "Wir besprechen Ihre Möglichkeiten und Sie entscheiden sich, wie Sie weiter vorgehen möchten.",
          done: false,
          icon: iconTipps,
        },
      ],
    },
    contractLaw: {
      headline: "So einfach funktioniert es",
      steps:
        product.name === "providerContract" && product.reasonForDispute === "schufaPositiveData"
          ? [
              {
                headline: "1. Fall schildern",
                content: "Sie beschreiben kurz Ihren Fall und laden Unterlagen zu Ihrem Vertrag hoch.",
                done: false,
                icon: iconDocument,
              },
              {
                headline: "2. SCHUFA-Anfrage",
                content: "Wir fordern eine SCHUFA-Auskunft für Sie an und prüfen, ob der Mobilfunkanbieter Ihre Daten weitergegeben hat.",
                done: false,
                icon: iconLaw,
              },
              {
                headline: "3. Schadenersatz einfordern",
                content: "Es wurden Daten weitergegeben? Dann fordern wir Schadenersatz für Sie ein!",
                done: false,
                icon: iconCourt,
              },
            ]
          : [
              {
                headline: "1. Fall schildern",
                content: "Sie beschreiben kurz Ihren Fall und laden Unterlagen zu Ihrem Vertrag hoch.",
                done: false,
                icon: iconDocument,
              },
              {
                headline: "2. Vorgehen festlegen",
                content: "Sie entscheiden sich, wie es weiter gehen soll und wir leiten alles in die Wege.",
                done: false,
                icon: iconLaw,
              },
              {
                headline: "3. Interessen durchsetzen",
                content: "Wir setzen uns schnell und professionell für Ihre rechtlichen Interessen ein.",
                done: false,
                icon: iconCourt,
              },
            ],
    },
    alimony: {
      headline: "Meine Analyse",
      steps: [
        {
          headline: "1. Situation schildern",
          content: "Machen Sie ganz einfach alle Angaben zu Ihrer Situation.",
          done: product.leadStatus >= leadStatusDocument.STATUS_DATA_COMPLETED,
          icon: iconDocument,
        },
        {
          headline: "2. Analyse und Berechnung erhalten",
          content: "Sie können Ihre Analyse jederzeit einsehen und herunterladen.",
          done: product.leadStatus >= leadStatusDocument.STATUS_COMPLETED,
          icon: iconDownload,
        },
        {
          headline: "3. Offene Fragen?",
          content: "Nutzen sie einfach den Link in Ihrer Analyse, um ein Erstgespräch zum Thema Unterhalt zu vereinbaren.",
          done: false,
          icon: iconTipps,
        },
      ],
    },
    death: {
      headline: "Mein persönlicher Ratgeber",
      steps: [
        {
          headline: "1. Situation schildern",
          content: "Schildern Sie kurz Ihre Situation und Fragen",
          done: product.leadStatus >= leadStatusDocument.STATUS_DATA_COMPLETED,
          icon: iconDocument,
        },
        {
          headline: "2. Ratgeber erhalten",
          content: "Sie können Ihren persönlichen Ratgeber jederzeit einsehen und herunterladen.",
          done: product.leadStatus >= leadStatusDocument.STATUS_COMPLETED,
          icon: iconDownload,
        },
        {
          headline: "3. Offene Fragen?",
          content: "Dann können Sie gerne über den Link in Ihrem Ratgeber ein Erstgespräch vereinbaren",
          done: false,
          icon: iconTipps,
        },
      ],
    },
    shortTimeWork: {
      headline: "Ablauf",
      steps: [
        {
          headline: "1. Situation schildern",
          content: (
            <>
              Schildern Sie in unserem <Link to={formLink(product)}>Assistenten</Link> Ihre derzeitige Arbeitssituation.
            </>
          ),
          done: false,
          icon: iconDocument,
        },
        {
          headline: "2. Analyse erhalten",
          content: "Sie können Ihre persönliche Analyse jederzeit kostenlos herunterladen.",
          done: false,
          icon: iconDownload,
        },
        {
          headline: "3. Weiteres Vorgehen",
          content: "Oft ist Kurzarbeit sinnvoll. In Einzelfällen macht auch z.B. eine Kündigungsschutzklage Sinn.",
          done: false,
          icon: iconTipps,
        },
      ],
    },
    default: null,
  };
  return _get(processConfig, product.productCategory === "contractLaw" ? product.productCategory : product.name);
};

export const getStatusBoxConfig = (product) => {
  let statusBoxConfig = {
    header: "Es ist ein Fehler aufgetreten",
    text: "",
    ctaButton: null,
    productName: "",
    percentage: 100,
    onClick: () => {},
  };

  let buttonTarget = "/";
  let buttonLabel = "";

  if (product.productCategory === "contractLaw") {
    return getStatusBoxConfigContractLawCase(product);
  }

  switch (product.name) {
    case "divorce":
      return getStatusBoxConfigDivorce(product);
    case "settlement":
      return getStatusBoxConfigSettlement(product);
    case "traffic":
      return getStatusBoxConfigTraffic(product);
    case "familyLawCase":
      return getStatusBoxConfigFamilyLawCase(product);
    case "alimony":
      return getStatusBoxConfigAlimony(product);
    case "death":
      return getStatusBoxConfigDeath(product);
    case "shortTimeWork":
      return getStatusBoxConfigShortTimeWork(product);
    default:
      break;
  }

  statusBoxConfig.ctaButton = (
    <Button component={Link} to={buttonTarget} variant="contained" color="primary" fullWidth onClick={statusBoxConfig.onClick}>
      {buttonLabel}
    </Button>
  );

  return statusBoxConfig;
};

export const getAppointmentDisplayType = (product) => {
  const lawyerCounselingCallStatus = _get(product, "lawyerCounselingCall.status");
  const advisoryAppointmentStatus = _get(product, "advisoryAppointment.status");

  //if theres an open appointment. only works while created is the only "open" status
  if (lawyerCounselingCallStatus === "created" || advisoryAppointmentStatus === "created") {
    return "appointments";
  }

  if (
    //is advisory product
    (_includes(["death", "alimony", "familyLawCase", "shortTimeWork"], product.name) ||
      (product.productCategory === "contractLaw" && product.caseCategory === "advisory")) &&
    //and has both appointments completed
    advisoryAppointmentStatus === "processed" &&
    lawyerCounselingCallStatus === "processed"
  ) {
    return "limitReached";
  }

  return "bookingWidget";
};

export const splitUpByCompletedStatus = (minimalCases) => {
  let cases = _orderBy(minimalCases, ["created"], ["desc"]);
  let closedCases = _remove(cases, (minimalCase) => minimalCase.isCompleted || minimalCase.backofficeCase.won || minimalCase.backofficeCase.lost);
  return { cases, closedCases };
};
