import React, { useState } from "react";
import InquiryToCustomerInformationBox from "./InquiryToCustomerInformationBox";
import InquiryToCustomerDocumentsBox from "./InquiryToCustomerDocumentsBox";
import InquiryToCustomer, { InquiryToCustomerStatus } from "../../../types/Entities/InquiryToCustomer";
import { Box, Paper, Typography } from "@mui/material";
import { FormProvider, useForm } from "../../../provider/Form/FormProvider";
import { apiPut } from "../../../services/apiClient";
import TextContainer from "../../Container/TextContainer";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import ButtonLoading from "../../Button/ButtonLoading";
import _isEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router";
import { getCaseLink, getProductNameBy } from "../../../services/productService";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../hooks/useProductParams";

type InquiryToCustomerInformationAndDocumentsProps = {
  inquiryToCustomer: InquiryToCustomer;
  product: AbstractCase;
};

export default function InquiryToCustomerInformationAndDocuments({ inquiryToCustomer, product }: InquiryToCustomerInformationAndDocumentsProps) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { productName, productId } = useProductParams();
  const productClassName = getProductNameBy("urlPartShort", productName);

  if (!inquiryToCustomer.requestedDocuments) {
    return null;
  }

  const handleSubmit = async ({ values }: { values: Record<string, any> }) => {
    setIsLoading(true);
    let payload: Record<string, any> = {
      status: InquiryToCustomerStatus.STATUS_DONE,
      doneBy: "customer",
    };
    switch (inquiryToCustomer.inquiryType) {
      case "documents":
        payload.inquiryTextDocumentsCustomer = values.inquiryTextDocumentsCustomer;
        break;
      case "information":
        payload.inquiryTextInformationCustomer = values.inquiryTextInformationCustomer;
        break;
      case "informationAndDocuments":
        payload.inquiryTextDocumentsCustomer = values.inquiryTextDocumentsCustomer;
        payload.inquiryTextInformationCustomer = values.inquiryTextInformationCustomer;
        break;
      default:
        break;
    }

    await apiPut("/inquiry_to_customers/" + inquiryToCustomer.id, payload);
    setIsLoading(false);

    const inquiryFilters = {
      product: productClassName,
      productId: productId,
      "status[0]": InquiryToCustomerStatus.STATUS_OPEN,
      "status[1]": InquiryToCustomerStatus.STATUS_OVERDUE,
    };
    const inquiryApiUri = "/inquiry_to_customers";
    queryClient.invalidateQueries(queryKeys.collection(inquiryApiUri, inquiryFilters));
    navigate(getCaseLink(product) + "/anfrage/beantwortet");
  };

  let initialValues = {
    inquiryTextInformationCustomer: "",
    inquiryTextDocumentsCustomer: "",
  };

  inquiryToCustomer.requestedDocuments.forEach((requestedDocument, index) => {
    // @ts-ignore
    initialValues["requestedDocumentMissing" + index] = false;
  });

  return (
    <Paper sx={{ padding: "1rem" }}>
      <TextContainer>
        <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
          <InquiryToCustomerInformationAndDocumentsForm inquiryToCustomer={inquiryToCustomer} product={product} isLoading={isLoading} />
        </FormProvider>
      </TextContainer>
    </Paper>
  );
}

type InquiryToCustomerInformationAndDocumentsFormProps = {
  inquiryToCustomer: InquiryToCustomer;
  product: AbstractCase;
  isLoading: boolean;
};

const InquiryToCustomerInformationAndDocumentsForm = ({ inquiryToCustomer, product, isLoading }: InquiryToCustomerInformationAndDocumentsFormProps) => {
  const { handleSubmit, values, errors } = useForm();

  const isDocumentsRequest = ["documents", "informationAndDocuments"].includes(inquiryToCustomer.inquiryType);
  const isInformationRequest = ["information", "informationAndDocuments"].includes(inquiryToCustomer.inquiryType);

  const documentInfoMissing =
    isDocumentsRequest &&
    inquiryToCustomer.requestedDocuments?.some((requestedDocument, index) => {
      return requestedDocument.temporaryFilesToCombine.length < 1 && values["requestedDocumentMissing" + index] === false;
    });

  return (
    <form onSubmit={handleSubmit}>
      {isInformationRequest && <InquiryToCustomerInformationBox inquiryToCustomer={inquiryToCustomer} />}
      {isDocumentsRequest && (
        <Box sx={{ marginTop: isInformationRequest ? "4rem" : 0 }}>
          <InquiryToCustomerDocumentsBox inquiryToCustomer={inquiryToCustomer} product={product} />
        </Box>
      )}

      <Box sx={{ marginTop: "4rem" }}>
        {(!_isEmpty(errors) || documentInfoMissing) && (
          <Typography variant={"body2"} sx={{ color: "red", textAlign: "center" }}>
            Es liegen leider nicht alle angeforderten Informationen/Dokumente vor. Bitte prüfen Sie Ihre Angaben oben.
          </Typography>
        )}
        <ButtonLoading
          fullWidth
          variant={"contained"}
          color={"primary"}
          type={"submit"}
          isLoading={isLoading}
          disabled={documentInfoMissing || !_isEmpty(errors)}
        >
          Rückmeldung abschicken
        </ButtonLoading>
      </Box>
    </form>
  );
};
