const mainTeaserStyle = (theme) => ({
  mainTeaserContainer: {
    margin: "5rem 1rem",
    [theme.breakpoints.up("md")]: {
      margin: "5rem",
    },
  },
  subText: {
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
    },
    margin: "1rem 0",
  },
  button: {
    margin: "0 auto",
    [theme.breakpoints.down('md')]: {
      display: "block",
    },
    minWidth: 300,
  },
  headline: {
    marginTop: "12rem",
    [theme.breakpoints.down('lg')]: {
      marginTop: "3rem",
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      textAlign: "center",
    },
  },
});

export default mainTeaserStyle;
