import React, { useCallback, useEffect, useMemo, useState } from "react";
import RedirectService from "../../services/RedirectService";
import { getProductData } from "../../services/productService";
import { useLocation, useNavigate } from "react-router-dom";
import { gaSet } from "../../services/cookieService";
import { Helmet } from "react-helmet-async";
import Container from "../Container/Container";
import Layout from "../../containers/Layouts/Layout";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import _isEmpty from "lodash/isEmpty";
import * as messagesActions from "../../store/messages/actions";
import { FormProvider } from "../../provider/Form/FormProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {createResource, queryKeys, updateResource} from "../../services/reactQuery/reactQueryService";
import { useCustomer } from "../../provider/Customer/CustomerProvider";

export default function ApplicationForm({ product }) {
  const redirectService = RedirectService;
  const formService = getProductData(product.name, "formService");
  const applicationStatusSummary = getProductData(product.name, "summaryStatus");
  const { customer } = useCustomer();
  const location = useLocation();
  const navigate = useNavigate();

  const [apiErrors, setApiErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const applicationStatus = product.applicationStatus;
  const pathName = location.pathname;
  const [currentStatus, setCurrentStatus] = useState(null);
  const [formStep, setFormStep] = useState();

  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, data.id), data),
  });
  const createMutation = useMutation(createResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, data.id), data),
  });

  useEffect(() => {
    const targetPath = redirectService.getApplicationLocation(product, pathName);
    if (targetPath !== pathName) {
      navigate(targetPath, { replace: true });
    }
    setCurrentStatus(redirectService.getStatusByLocation(pathName, product) || applicationStatus);
  }, [pathName, product, applicationStatus]);

  useEffect(() => {
    setInitialValues(formService.prepareFormData(product, customer));
  }, [product]);

  useEffect(() => {
    if (initialValues) {
      const currentformStep = formService.getStep(currentStatus, initialValues);
      setFormStep(currentformStep);
      gaSet({ title: currentformStep.title });
    }
  }, [initialValues, currentStatus]);

  const formKey = useMemo(() => {
    return new Date().getTime();
  }, [initialValues]);

  const isSummaryEdit = useCallback(() => {
    const redirectServiceStatus = redirectService.getStatusByLocation(pathName, product);
    return (
      !!location.state && !!location.state.field && product.applicationStatus === applicationStatusSummary && redirectServiceStatus !== applicationStatusSummary
    );
  }, [pathName, location, applicationStatusSummary]);

  const getSubmitProps = useCallback(() => {
    if (isSummaryEdit()) {
      return {
        hideBackButton: true,
        nextText: "Speichern und zur Übersicht",
        isLoading: isLoading,
      };
    }

    const previousStep = formService.getStep(currentStatus, initialValues, -1);
    return {
      backLink: previousStep ? redirectService.getApplicationStep(previousStep.applicationStatus, product) : null,
      hideBackButton: !previousStep,
      isLoading: isLoading,
    };
  }, [isLoading, formStep]);

  const submitAction = useCallback(
    async ({ values, options }) => {
      setIsLoading(true);
      const nextStep = formService.getStep(currentStatus, values, 1);

      try {
        await formService.save(values, currentStatus, nextStep.applicationStatus, product, customer, updateMutation, queryClient, createMutation);

        if (!options.preventRedirect) {
          isSummaryEdit()
            ? navigate(redirectService.getApplicationStep(applicationStatusSummary, product), { state: location.state })
            : navigate(redirectService.getApplicationStep(nextStep.applicationStatus, product));
        }
        setApiErrors({});
      } catch (error) {
        if (!_isEmpty(error.errors)) {
          setIsLoading(false);
          setApiErrors(error.errors);
          return;
        }
        messagesActions.addMessage({
          type: "error",
          text: "Es ist ein technischer Fehler aufgetreten: " + error.message,
        });
        console.error(error);
      }
      setIsLoading(false);
    },
    [currentStatus, applicationStatusSummary, product]
  );

  if (!initialValues || !formStep) {
    return (
      <Layout product={product.name}>
        <Container>
          <Container style={{ width: 250 }}>
            <LegalbirdLoader />
          </Container>
        </Container>
      </Layout>
    );
  }

  return (
    <>
      <Helmet>
        <title>{formStep.title}</title>
      </Helmet>
      <Container>
        <FormProvider key={formKey} initialValues={initialValues} submitAction={submitAction} externalErrors={apiErrors}>
          {formStep.render({
            product,
            submitProps: getSubmitProps(),
          })}
        </FormProvider>
      </Container>
    </>
  );
}
