/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import _has from "lodash/has";
import _pick from "lodash/pick";
import _merge from "lodash/merge";
import moment from "moment";
import {
  fromBoolean,
  fromDate,
  fromFloat,
  fromIban,
  fromNumber,
  handleSimpleArray,
  pickAndConvert,
  reduceSimpleArray,
  sanitizeAddressData,
  sanitizePersonData,
  toBoolean,
  toDate,
  toFloat,
  toIban,
  toNumber,
  trimValueIfSet,
} from "../formServiceFunctions";
import {
  APPLICATION_STATUS_CONTRACT,
  APPLICATION_STATUS_DOCUMENTS,
  APPLICATION_STATUS_DONE,
  APPLICATION_STATUS_LEGAL_INSURANCE,
  APPLICATION_STATUS_PERSONAL,
  APPLICATION_STATUS_POWER_OF_ATTORNEY,
  APPLICATION_STATUS_SUMMARY,
  APPLICATION_STATUS_TERMINATION,
} from "./applicationStatus";
import _findIndex from "lodash/findIndex";
import formSteps from "./formSteps";
import ReactGA from "react-ga4";
import _forEach from "lodash/forEach";
import _set from "lodash/set";
import _get from "lodash/get";
import _cloneDeep from "lodash/cloneDeep";
import _isObjectLike from "lodash/isObjectLike";
import ApiClient from "../apiClient";
import { getProductData } from "../productService";
import _isEmpty from "lodash/isEmpty";

class FormService {
  dataFields = {
    [APPLICATION_STATUS_TERMINATION]: {
      settlement: ["contractTerminated", "terminationObstacles", "contractTerminationReceivedDate", "terminationNoticeType", "preferredOutcome"],
      clientBankData: ["iban"],
    },
    [APPLICATION_STATUS_CONTRACT]: {
      settlement: ["employedSince", "employedSinceYear", "employedSinceMonth", "grossSalary", "jobDescription", "numberOfEmployees", "worksCouncil"],
      employerOrganization: ["name", "email", "telephone"],
      "employerOrganization.address": [
        "streetAddress",
        "postalCode",
        "addressLocality",
        "postOfficeBoxNumber",
        "postOfficeBoxPostalCode",
        "postOfficeBoxAddressLocality",
      ],
    },
    [APPLICATION_STATUS_LEGAL_INSURANCE]: {
      settlement: ["legalExpenseInsurance", "noWinNoFee", "paymentType"],
      insurance: ["insurance", "caseReferenceNumber", "insurancePolicyNumber", "policyHolderType", "deductible"],
      "insurance.policyHolder": ["gender", "givenName", "familyName"],
    },
    [APPLICATION_STATUS_PERSONAL]: {
      settlement: ["maritalStatus", "partnerIsWorking", "numberChildren"],
      client: ["gender", "givenName", "familyName", "birthDate"],
      "client.residenceAddress": ["streetAddress", "postalCode", "addressLocality"],
      customer: ["telephone", "contactTime"],
    },
    [APPLICATION_STATUS_SUMMARY]: {
      settlement: ["personalTextMessage"],
    },
    [APPLICATION_STATUS_POWER_OF_ATTORNEY]: {
      settlement: ["powerOfAttorney"],
    },
    [APPLICATION_STATUS_DOCUMENTS]: {
      settlement: [],
    },
  };
  conversionFields = [
    {
      path: "clientBankData.iban",
      convertFrom: (value) => fromIban(value),
      convertTo: (value) => toIban(value),
    },
    {
      path: "contractTerminated",
      convertFrom: (value) => fromBoolean(value),
      convertTo: (value) => toBoolean(value),
    },
    {
      path: "worksCouncil",
      convertFrom: (value) => fromBoolean(value),
      convertTo: (value) => toBoolean(value),
    },
    {
      path: "legalExpenseInsurance",
      convertFrom: (value) => fromBoolean(value),
      convertTo: (value) => toBoolean(value),
    },
    {
      path: "partnerIsWorking",
      convertFrom: (value) => fromBoolean(value),
      convertTo: (value) => toBoolean(value),
    },
    {
      path: "grossSalary",
      convertFrom: (value) => fromNumber(value),
      convertTo: (value) => toNumber(value),
    },
    {
      path: "numberChildren",
      convertFrom: (value) => fromNumber(value),
      convertTo: (value) => toNumber(value),
    },
    {
      path: "insurance.deductible",
      convertFrom: (value) => fromFloat(value),
      convertTo: (value) => toFloat(value),
    },
    {
      path: "numberOfEmployees",
      convertFrom: (value) => fromNumber(value),
      convertTo: (value) => toNumber(value),
    },
    {
      path: "terminationObstacles",
      convertFrom: (value) => reduceSimpleArray(value),
      convertTo: (value) => handleSimpleArray(value),
    },
    {
      path: "contractTerminationReceivedDate",
      convertFrom: (value) => fromDate(value),
      convertTo: (value) => toDate(value),
    },
    {
      path: "client.birthDate",
      convertFrom: (value) => fromDate(value),
      convertTo: (value) => toDate(value),
    },
    {
      path: "powerOfAttorney",
      convertFrom: (value) => !!value,
      convertTo: () => new Date(),
    },
    {
      path: "insurance.insurance",
      convertFrom: (value) => (_isObjectLike(value) ? "/insurances/" + value.id : value),
      convertTo: (value) => value,
    },
  ];
  //-- callback function will be called AFTER this STATUS is passed
  statusFunctions = {
    [APPLICATION_STATUS_POWER_OF_ATTORNEY]: () => {
      ReactGA.event({
        category: "Power of Attorney",
        action: "Settlement PoA granted",
      });
    },
    [APPLICATION_STATUS_DOCUMENTS]: () => {
      ReactGA.event({
        category: "Document Upload",
        action: "Settlement Documents uploaded",
      });
    },
  };

  save = async (formData, applicationStatus, nextApplicationStatus, settlement, customer, updateMutation) => {
    const fields = this.dataFields[applicationStatus];
    const resultData = this.handleSettlementFields(formData);

    if (_has(fields, "customer")) {
      const customerData = _pick(resultData.customer, fields.customer);
      await updateMutation.mutateAsync({
        uri: "/customers",
        id: customer.id,
        data: customerData,
      });
    }

    if (_has(fields, "client")) {
      let clientData = _pick(resultData.client, fields.client);
      clientData = sanitizePersonData(clientData);
      await updateMutation.mutateAsync({
        uri: "/people",
        id: settlement.client.id,
        data: clientData,
      });
    }

    if (_has(fields, "client.residenceAddress")) {
      let addressData = _pick(resultData.client.residenceAddress, fields["client.residenceAddress"]);
      addressData = sanitizeAddressData(addressData);
      await updateMutation.mutateAsync({
        uri: "/addresses",
        id: settlement.client.residenceAddress.id,
        data: addressData,
      });
    }

    if (_has(fields, "employerOrganization")) {
      let employerData = _pick(resultData.employerOrganization, fields.employerOrganization);
      employerData = this.sanitizeEmployerData(employerData);
      await updateMutation.mutateAsync({
        uri: "/organizations",
        id: settlement.employerOrganization.id,
        data: employerData,
      });
    }

    if (_has(fields, "employerOrganization.address")) {
      let addressData = _pick(resultData.employerOrganization.address, fields["employerOrganization.address"]);
      addressData = sanitizeAddressData(addressData);
      await updateMutation.mutateAsync({
        uri: "/addresses",
        id: settlement.employerOrganization.address.id,
        data: addressData,
      });
    }

    if (_has(fields, "insurance")) {
      const insuranceData = pickAndConvert(resultData.insurance, fields["insurance"]);
      await ApiClient.put("/customer_insurances/" + settlement.insurance.id, { body: JSON.stringify(insuranceData) });
    }

    if (_has(fields, "clientBankData")) {
      const clientBankData = pickAndConvert(resultData.clientBankData, fields["clientBankData"]);

      if (clientBankData.iban === "DE") {
        delete clientBankData.iban;
      }

      if (!_isEmpty(clientBankData)) {
        await updateMutation.mutateAsync({
          uri: "/bank_datas",
          id: formData.clientBankData.id,
          data: clientBankData,
        });
      }

    }

    if (_has(fields, "insurance.policyHolder")) {
      const basePolicyHolderData = formData.insurance.policyHolderType === "me" ? resultData.customer.person : resultData.insurance.policyHolder;
      let policyHolderData = pickAndConvert(basePolicyHolderData, fields["insurance.policyHolder"]);
      policyHolderData = sanitizePersonData(policyHolderData);
      await updateMutation.mutateAsync({
        uri: "/people",
        id: settlement.insurance.policyHolder.id,
        data: policyHolderData,
      });
    }

    let settlementData = {};
    if (nextApplicationStatus) {
      settlementData.applicationStatus = nextApplicationStatus;
      this.statusFunctions[applicationStatus] && this.statusFunctions[applicationStatus]();
    }

    if (_has(fields, "settlement")) {
      settlementData = _merge(settlementData, _pick(resultData, fields.settlement));
    }

    await updateMutation.mutateAsync({
      uri: getProductData(settlement.name, "apiUri"),
      id: settlement.id,
      data: settlementData,
    });
  };

  sanitizeEmployerData = (employerData) => {
    let sanitizedEmployerData = _cloneDeep(employerData);
    sanitizedEmployerData = trimValueIfSet("name", sanitizedEmployerData);
    sanitizedEmployerData = trimValueIfSet("telephone", sanitizedEmployerData);
    sanitizedEmployerData = trimValueIfSet("email", sanitizedEmployerData);
    return sanitizedEmployerData;
  };

  handleSettlementFields = (data) => {
    let resultData = _cloneDeep(data);
    _forEach(this.conversionFields, (conversionField) => {
      const { path, convertTo } = conversionField;
      if (_has(resultData, path)) {
        _set(resultData, path, convertTo(_get(resultData, path)));
      }
    });
    if (_has(resultData, "employedSinceYear") && _has(resultData, "employedSinceMonth")) {
      resultData.employedSince = `${resultData.employedSinceYear}-${resultData.employedSinceMonth}-01`;
      resultData.employedSinceYear = resultData.employedSinceMonth = undefined;
    }
    resultData.noWinNoFee = !resultData.legalExpenseInsurance;
    resultData.paymentType = resultData.legalExpenseInsurance ? "insurance" : "nwnf";

    return resultData;
  };

  prepareFormData = (settlement, customer) => {
    let resultData = _cloneDeep(settlement);
    resultData.customer = _cloneDeep(customer);
    _forEach(this.conversionFields, (conversionField) => {
      const { path, convertFrom } = conversionField;
      if (_has(resultData, path)) {
        _set(resultData, path, convertFrom(_get(resultData, path)));
      }
    });
    if (resultData.employedSince) {
      const employedSince = moment(resultData.employedSince);
      resultData.employedSinceMonth = employedSince.format("MM");
      resultData.employedSinceYear = employedSince.format("YYYY");
    } else {
      resultData.employedSinceMonth = null;
      resultData.employedSinceYear = null;
    }
    return resultData;
  };

  getStep(status, formData, addend = 0) {
    const steps = this.getAvailableSteps();
    const currentIndex = _findIndex(steps, (step) => step.applicationStatus === status);
    if (currentIndex === -1) {
      return steps[0];
    }
    return steps[currentIndex + addend];
  }

  getAvailableSteps = () => {
    let availableSteps = [];
    availableSteps.push(formSteps[APPLICATION_STATUS_TERMINATION]);
    availableSteps.push(formSteps[APPLICATION_STATUS_CONTRACT]);
    availableSteps.push(formSteps[APPLICATION_STATUS_LEGAL_INSURANCE]);
    availableSteps.push(formSteps[APPLICATION_STATUS_PERSONAL]);
    availableSteps.push(formSteps[APPLICATION_STATUS_SUMMARY]);
    availableSteps.push(formSteps[APPLICATION_STATUS_POWER_OF_ATTORNEY]);
    availableSteps.push(formSteps[APPLICATION_STATUS_DOCUMENTS]);
    availableSteps.push(formSteps[APPLICATION_STATUS_DONE]);
    return availableSteps;
  };
}

export default new FormService();
